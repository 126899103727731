import { defineStore } from "pinia";
import { computed, inject, reactive, ref } from "vue";
import type { Ref } from "vue";
import type ISwitchboardCategory from "@/types/ISwitchboardCategories";
import type IConfiguration from "@/types/IConfiguration";
import type ICase from "@/types/ICases";
import type IAccessories from "@/types/IAccessories";
import type IGrid from "@/types/IGrid";
import type IBoardSet from "@/types/IBoardSet";
import Functions from "@/plugins/Functions";
import type IProducer from "@/types/IProducer";
import type IBoardElement from "@/types/IBoardElement";
import type IBoardPosition from "@/types/IBoardPosition";
import type IBoardActionResponse from "@/types/IBoardActionResponse";
import type ISecurityProducer from "@/types/ISecurityProducer";
import type ISecurityElement from "@/types/ISecurityElement";
import type { GLTF } from "three/examples/jsm/loaders/GLTFLoader";
import { SwitchboardCategoriesType } from "@/types/SwitchboardCategoriesType";
import type IPhotovoltaicBoardElement from "@/types/IPhotovoltaicBoardElement";
import { securityElementsAPI } from "@/services/securityElementsService";
import type ISecurityProducers from "@/types/ISecurityProducers";
import { useLocaleStore } from "@/stores/locale";
import { useSecurityElementsStore } from "@/stores/securityElements";
import type IPreparedConfiguration from "@/types/IPreparedConfiguration";
import { toast } from "@/plugins/Toasts";
import { ToastType } from "@/types/ToastType";

export const useConfigurationStore = defineStore("configuration", () => {
  const _gltfCaseModel = ref<GLTF | null>(null);
  const _forceRepaintSeed = ref<string | null>(null);
  const _forceRepaint3DSeed = ref<string | null>(null);
  const _visualisation3d = ref();
  // region Properties
  const _configuration = reactive<IConfiguration>({
    category: {} as ISwitchboardCategory,
    case: {} as ICase,
    accessories: [] as Array<IAccessories>,
    selectedGridIndex: 0,
    selectedBoardSet: null as IBoardSet | null,
    selectedSecurityOtherProducer: {} as ISecurityProducer | null,
    selectedSecurityPhotovoltaicProducer: {} as ISecurityProducer | null,
    dataLoaded: false,
  } as IConfiguration);

  const _quantity = ref(1);

  const localeStore = useLocaleStore();
  const securityElementsStore = useSecurityElementsStore();
  const trans: any = inject("$t");

  // endregion

  // region Getters
  const isPhotovoltaic = computed(
    (): boolean | undefined =>
      _configuration.category &&
      _configuration.category.code === SwitchboardCategoriesType.PHOTOVOLTAIC
  );

  const isCamping = computed(
    (): boolean | undefined =>
      _configuration.category &&
      _configuration.category.code === SwitchboardCategoriesType.CAMPING
  );

  const gltfCaseModel = computed((): GLTF | null => _gltfCaseModel?.value);

  const forceRepaintWatcher = computed(
    (): string | null => _forceRepaintSeed.value
  );

  const forceRepaint3DWatcher = computed(
    (): string | null => _forceRepaint3DSeed.value
  );

  const configurationCurrencyCode = computed((): string =>
    _configuration.case?.pricings?.currencyCode
      ? _configuration.case.pricings.currencyCode
      : "PLN"
  );

  const configurationIp = computed((): number => {
    let tempIp = 0;

    if (_configuration?.case?.IP) {
      tempIp = _configuration?.case?.IP;

      if (_configuration.case.grids?.length) {
        _configuration.case.grids.forEach((grid: IGrid) => {
          if (grid.elements?.length) {
            grid.elements.forEach((element: IBoardSet) => {
              if (
                element?.producer?.board_element_details?.IP &&
                element.producer.board_element_details.IP < tempIp
              ) {
                tempIp = element.producer.board_element_details.IP;
              }

              if (
                element?.photovoltaicBoardElement?.IP &&
                element.photovoltaicBoardElement.IP < tempIp
              ) {
                tempIp = element.photovoltaicBoardElement.IP;
              }
            });
          }
        });
      }
    }

    return tempIp;
  });

  const configurationPrice = computed((): number => {
    let totalPrice = 0;

    if (_configuration?.case?.pricings?.amount) {
      totalPrice += _configuration.case.pricings.amount;
    }

    if (_configuration?.case?.grids?.length) {
      _configuration.case.grids.forEach((grid: IGrid) => {
        if (grid.elements?.length) {
          grid.elements.forEach((element: IBoardSet) => {
            if (element.producer?.price.amount) {
              totalPrice += element.producer.price.amount;
            }

            if (element.photovoltaicBoardElement?.price.amount) {
              totalPrice += element.photovoltaicBoardElement.price.amount;
            }
          });
        }
      });
    }

    if (_configuration?.case?.securityGrids?.length) {
      _configuration.case.securityGrids.forEach((grid: IGrid) => {
        if (grid.elements?.length) {
          grid.elements.forEach((element: IBoardSet) => {
            if (element.securityElement?.price.amount) {
              totalPrice += element.securityElement.price.amount;
            }
          });
        }
      });
    }

    if (_configuration.accessories?.length) {
      _configuration.accessories.forEach((accessory: IAccessories) => {
        if (accessory.pricings?.amount) {
          totalPrice += accessory.pricings.amount;
        }
      });
    }

    return totalPrice * _quantity.value;
  });

  const configurationGrossPrice = computed((): number => {
    return (
      configurationPrice.value *
      ((100 + Number(import.meta.env.VITE_VAT_PL)) / 100)
    );
  });

  const configuration = computed(
    (): IConfiguration => _configuration as IConfiguration
  );

  const selectedCase = computed((): ICase | undefined => _configuration.case);

  const grids = computed((): IGrid[] =>
    _configuration.case?.grids ? _configuration.case.grids : []
  );

  const securityGrids = computed((): IGrid[] =>
    _configuration.case?.securityGrids ? _configuration.case.securityGrids : []
  );

  const selectedGrid = computed((): IGrid | null =>
    _configuration.case &&
    _configuration.case.grids &&
    _configuration?.case?.grids?.length > _configuration.selectedGridIndex
      ? _configuration.case.grids[_configuration.selectedGridIndex]
      : null
  );

  const selectedBoardSet = computed(
    (): IBoardSet | null => _configuration.selectedBoardSet
  );

  const selectedSecurityGrid = computed((): IGrid | null =>
    _configuration.case &&
    _configuration.case.securityGrids &&
    _configuration?.case?.securityGrids?.length >
      _configuration.selectedGridIndex
      ? _configuration.case.securityGrids[_configuration.selectedGridIndex]
      : null
  );

  const dataLoaded = computed((): boolean => {
    return _configuration!.dataLoaded;
  });

  const quantity = computed((): number => {
    return _quantity.value ? _quantity.value : 1;
  });

  const configurationList = computed((): any => {
    const list: any = [_configuration.case];
    return _configuration.accessories?.length
      ? list.concat(_configuration.accessories)
      : list;
  });

  function setQuantity(value: number): void {
    if (value <= 0 || !value) {
      _quantity.value = 1;
    } else if (value >= 9999) {
      _quantity.value = 9999;
    } else {
      _quantity.value = value;
    }
  }

  function getConfiguration(): IConfiguration {
    return _configuration as IConfiguration;
  }

  function getConfigurationCategoryId(): number {
    return _configuration!.category!.id;
  }

  function getCaseId(): number {
    return _configuration!.case!.id;
  }

  function getGridElementById(gridElementId: string): IBoardSet | null {
    if (
      !selectedGrid.value ||
      !selectedGrid.value.elements ||
      !selectedGrid.value.elements.length
    ) {
      return null;
    }

    const indexOfElement = selectedGrid.value.elements.findIndex(
      (item) => item.uId === gridElementId
    );

    if (indexOfElement > -1) {
      return selectedGrid.value.elements[indexOfElement];
    }

    return null;
  }
  // endregion

  // region Actions
  function setVisualisation3dRef(visualisation3d: Ref) {
    _visualisation3d.value = visualisation3d;
  }
  function getPreparedConfigurationToSend():
    | IPreparedConfiguration
    | undefined {
    if (!_configuration?.case?.producer) {
      return;
    }

    const preparedConfiguration: IPreparedConfiguration = {
      case: null,
      arrayElements: [],
      securities: [],
      accessories: [],
      totalNetPrice: 0,
      totalGrossPrice: 0,
      quantity: 1,
    };

    preparedConfiguration.case = {
      id: _configuration?.case.id,
      name: _configuration?.case.name,
      producer: _configuration?.case.producer.name,
      pricing: _configuration?.case.pricings,
    };

    if (_configuration.case?.grids) {
      _configuration.case.grids.forEach((grid: IGrid) => {
        grid.elements.forEach((element: IBoardSet) => {
          if (
            element.boardElement &&
            element.producer &&
            !element.photovoltaicBoardElement
          ) {
            const preparedElement = {
              name: element.boardElement.name,
              producer: element.producer.name,
              pricing: element.producer.price,
            };

            preparedConfiguration.arrayElements.push(preparedElement);
          }

          if (
            element.boardElement &&
            element.producer &&
            element.photovoltaicBoardElement
          ) {
            const preparedElement = {
              name: element.boardElement.name,
              producer: element.producer.name,
              pricing: element.photovoltaicBoardElement.price,
            };

            preparedConfiguration.arrayElements.push(preparedElement);
          }
        });
      });
    }

    if (_configuration.case?.securityGrids) {
      _configuration.case.securityGrids.forEach((grid: IGrid) => {
        grid.elements.forEach((element: IBoardSet) => {
          if (element.securityElement && element.securityProducer) {
            const preparedElement = {
              name: element.securityElement.name,
              producer: element.securityProducer.name,
              pricing: element.securityElement.price,
            };

            preparedConfiguration.securities.push(preparedElement);
          }
        });
      });
    }

    if (_configuration.accessories?.length) {
      _configuration.accessories.forEach((accessory: IAccessories) => {
        if (!accessory.name) {
          return;
        }

        const preparedElement = {
          name: accessory.name,
          pricing: accessory.pricings,
        };

        preparedConfiguration.accessories.push(preparedElement);
      });
    }

    preparedConfiguration.totalNetPrice = Number(
      configurationPrice.value.toFixed(2)
    );

    if (localeStore.locale === "pl") {
      preparedConfiguration.totalGrossPrice = Number(
        configurationGrossPrice.value.toFixed(2)
      );
    }

    preparedConfiguration.quantity = quantity.value;

    return preparedConfiguration;
  }

  function storeCaseGltfModel(model: null | GLTF) {
    _gltfCaseModel.value = model;
  }

  function setSelectedBoardSet(boardSet: IBoardSet | null) {
    if (
      (boardSet == null && _configuration.selectedBoardSet != null) ||
      boardSet?.boardElement ||
      boardSet?.securityElement
    ) {
      _configuration.selectedBoardSet = boardSet;
      forceRepaint3D();
    }
  }

  function updateGridElementPosition(
    gridElementId: string,
    position: IBoardPosition
  ) {
    const gridElement = getGridElementById(gridElementId);

    if (gridElement) {
      gridElement.position = position;
      forceRepaint3D();
    } else {
      forceRepaint();
    }
  }

  function forceRepaint() {
    _forceRepaintSeed.value = Functions.uId();
  }

  function forceRepaint3D() {
    _forceRepaint3DSeed.value = Functions.uId();
  }

  function clearBoardElementsFromGrid(): void {
    if (_configuration.case?.grids) {
      for (const grid of _configuration.case.grids) {
        while (grid.elements.length) {
          grid.elements.pop();
        }
      }
    }
  }

  function addSuggestedPhotovoltaicBoardElements(
    elements: IPhotovoltaicBoardElement[]
  ): void {
    if (!_configuration.case || !_configuration.case.grids) {
      return;
    }

    clearBoardElementsFromGrid();

    if (_configuration?.case?.grids[0]) {
      elements.forEach((element) => {
        const _photovoltaicElement: IBoardSet = {
          uId: Functions.uId(),
          photovoltaicBoardElement: element,
        };

        _configuration.case!.grids![0]!.elements.push(_photovoltaicElement);
      });
    }
  }

  // region security actions
  function getSelectedElementsIds(): number[] {
    const selectedElementIds: number[] = [];

    grids.value.forEach((grid: IGrid) => {
      grid.elements.forEach((element: IBoardSet) => {
        if (element?.boardElement) {
          selectedElementIds.push(element.boardElement.id);
        }

        if (element?.photovoltaicBoardElement) {
          selectedElementIds.push(element.photovoltaicBoardElement.id);
        }
      });
    });

    return selectedElementIds;
  }

  function mapSelectedElements(): { [key: number]: number } {
    const selectedElementsIds = getSelectedElementsIds();
    const selectedElements: { [key: number]: number } = {};

    for (const elementId of selectedElementsIds.flat()) {
      if (selectedElements[elementId]) {
        selectedElements[elementId] += 1;
      } else {
        selectedElements[elementId] = 1;
      }
    }

    return selectedElements;
  }

  async function getProducersWithElementsSecurities(): Promise<void> {
    securityElementsStore.setLoadingData(true);

    if (!selectedCase.value) {
      return;
    }

    const [error, data] =
      await securityElementsAPI.getProducersWithElementsSecurities(
        selectedCase.value.id,
        mapSelectedElements(),
        localeStore.locale
      );

    if (error) {
      console.error("Error! ", error);
    } else {
      securityElementsStore.setSecurityProducers(data as ISecurityProducers);
    }

    securityElementsStore.setLoadingData(false);
  }
  function deleteSecurityElementsFromSelected(boardSetUid: string): void {
    if (
      !selectedSecurityGrid.value ||
      !selectedSecurityGrid.value.elements ||
      !selectedSecurityGrid.value.elements.length
    ) {
      return;
    }

    const indexToDelete = selectedSecurityGrid.value.elements.findIndex(
      (item) => item.uId === boardSetUid
    );

    if (indexToDelete > -1) {
      selectedSecurityGrid.value.elements.splice(indexToDelete, 1);
      forceRepaint();
    }
  }

  function setSelectedSecurityOtherProducer(producer: ISecurityProducer): void {
    let allAvailableForSelectedProducer = true;

    _configuration.selectedSecurityOtherProducer = producer;

    if (_configuration?.case?.securityGrids) {
      _configuration.case.securityGrids.forEach((grid: IGrid) => {
        grid.elements.forEach((element: IBoardSet) => {
          if (
            element.securityElement &&
            !element.securityElement.photovoltaic
          ) {
            const newSecurityElement =
              _configuration.selectedSecurityOtherProducer.fuses.find(
                (boardSet) => boardSet.id == element.securityElement?.id
              );

            if (newSecurityElement) {
              element.securityElement = newSecurityElement;
              element.securityProducer = producer;
              element.noAvailableForSelectedProducer = false;
            } else if (element.securityElement && element.securityProducer) {
              element.noAvailableForSelectedProducer = true;
              allAvailableForSelectedProducer = false;
            }
          }
        });
      });

      if (!allAvailableForSelectedProducer) {
        toast(trans("selectedProducerHasNotElement"), ToastType.ERROR);
      }
    }

    forceRepaint();
  }

  function setSelectedPhotovoltaicOtherProducer(
    producer: ISecurityProducer
  ): void {
    let allAvailableForSelectedProducer = true;

    _configuration.selectedSecurityPhotovoltaicProducer = producer;

    if (_configuration?.case?.securityGrids) {
      _configuration.case.securityGrids.forEach((grid: IGrid) => {
        grid.elements.forEach((element: IBoardSet) => {
          if (element.securityElement && element.securityElement.photovoltaic) {
            const newSecurityElement =
              _configuration.selectedSecurityPhotovoltaicProducer.fuses.find(
                (boardSet) => boardSet.id == element.securityElement?.id
              );

            if (newSecurityElement) {
              element.securityElement = newSecurityElement;
              element.securityProducer = producer;
              element.noAvailableForSelectedProducer = false;
            } else if (element.securityElement && element.securityProducer) {
              element.noAvailableForSelectedProducer = true;
              allAvailableForSelectedProducer = false;
            }
          }
        });
      });

      if (!allAvailableForSelectedProducer) {
        toast(trans("selectedProducerHasNotElement"), ToastType.ERROR);
      }
    }

    forceRepaint();
  }

  function selectSecurityElementOfBoardSet(
    boardSetUid: string,
    securityElement: ISecurityElement
  ): boolean {
    if (!selectedSecurityGrid.value) return false;

    const selectedBoardSet = selectedSecurityGrid.value.elements.find(
      (boardSet) => boardSet.uId == boardSetUid
    );

    if (!selectedBoardSet) {
      return false;
    }

    const emptyFieldsOnGrid = _getEmptyGridFields(selectedSecurityGrid.value);
    const currentElementSize = selectedBoardSet.securityElement
      ? selectedBoardSet.securityElement.fields_number
      : 0;

    if (
      emptyFieldsOnGrid + currentElementSize <
      securityElement.fields_number
    ) {
      return false;
    }

    selectedBoardSet.securityElement = securityElement;
    selectedBoardSet.noAvailableForSelectedProducer = false;

    if (
      _configuration.selectedSecurityOtherProducer &&
      !securityElement.photovoltaic
    ) {
      selectSecurityProducerOfBoardSet(
        boardSetUid,
        _configuration.selectedSecurityOtherProducer
      );
    } else if (
      _configuration.selectedSecurityPhotovoltaicProducer &&
      securityElement.photovoltaic
    ) {
      selectSecurityProducerOfBoardSet(
        boardSetUid,
        _configuration.selectedSecurityPhotovoltaicProducer
      );
    }

    setSelectedBoardSet(selectedBoardSet);

    return true;
  }

  function selectSecurityProducerOfBoardSet(
    boardSetUid: string,
    producer?: ISecurityProducer
  ) {
    if (!selectedSecurityGrid.value) return;

    const selectedBoardSet = selectedSecurityGrid.value?.elements.find(
      (boardSet) => boardSet.uId == boardSetUid
    );

    if (!selectedBoardSet) return;

    if (producer) {
      selectedBoardSet.securityProducer = producer;
    } else {
      selectedBoardSet.securityProducer = null;
    }

    addEmptySecurityElementIfAllCompleted();
  }

  function addEmptySecurityElementIfAllCompleted(
    gridIndex: number = _configuration.selectedGridIndex
  ): void {
    if (!_configuration.case || !_configuration.case.securityGrids) return;

    let completed = true;

    for (const element of _configuration.case.securityGrids[gridIndex]
      .elements) {
      if (!element.securityElement) {
        completed = false;

        break;
      }
    }

    if (completed) {
      addEmptySecurityElement(gridIndex);
    }
  }

  function addEmptySecurityElement(gridId?: number): void {
    if (!_configuration.case || !_configuration.case.securityGrids) {
      return;
    }

    if (gridId == undefined) {
      for (const grid of _configuration.case.securityGrids) {
        const _emptyElement: IBoardSet = {
          uId: Functions.uId(),
        };

        grid.elements.push(_emptyElement);
      }

      return;
    }

    if (_configuration.case.securityGrids[gridId]) {
      const _emptyElement: IBoardSet = {
        uId: Functions.uId(),
      };

      _configuration.case.securityGrids[gridId].elements.push(_emptyElement);
    }
  }

  function setCaseSecurityGrids(grids: Array<IGrid>): void {
    if (_configuration.case && _configuration.case.securityGrids) {
      clearCaseSecurityGrids();

      for (const grid of grids) {
        if (!grid.elements) {
          grid.elements = [];
        }

        _configuration.case!.securityGrids.push(grid);
      }
    }
  }

  function clearCaseSecurityGrids(): void {
    if (_configuration.case && _configuration.case.securityGrids) {
      while (_configuration.case!.securityGrids.length) {
        _configuration.case!.securityGrids.pop();
      }
    }

    securityElementsStore.setSecurityGridsLoaded(false);
  }

  function clearSecurityGridElements(): void {
    if (_configuration.case?.securityGrids) {
      for (const grid of _configuration.case.securityGrids) {
        while (grid.elements.length) {
          grid.elements.pop();
        }
      }
    }
  }

  function addSuggestedSecurityElementsToGrid(suggestedElements: IBoardSet[]) {
    if (!_configuration?.case?.securityGrids) {
      return;
    }

    clearSecurityGridElements();

    const suggestedResidualCurrentOthersElements = suggestedElements.filter(
      (element) => element?.securityElement?.type === "residual_current"
    );

    const suggestedOthersElements = suggestedElements.filter(
      (element) => element?.securityElement?.type !== "residual_current"
    );

    _addSuggestedElementsToGridIfCan(suggestedResidualCurrentOthersElements);
    _addSuggestedElementsToGridIfCan(suggestedOthersElements);

    if (_configuration?.case?.securityGrids) {
      for (let i = 0; i < _configuration.case!.securityGrids.length; i++) {
        addEmptySecurityElement(i);
      }
    }

    if (_visualisation3d.value) {
      _visualisation3d.value.repaint3dModelsOfAllGrids();
    }
  }

  function _addSuggestedElementsToGridIfCan(suggestedElements: IBoardSet[]) {
    if (!_configuration?.case?.securityGrids) {
      return;
    }

    for (const suggestedElement of suggestedElements) {
      for (const grid of _configuration.case.securityGrids) {
        const emptyGridFields = _getEmptyGridFields(grid);

        if (
          suggestedElement.securityElement &&
          emptyGridFields >= suggestedElement.securityElement.fields_number
        ) {
          grid.elements.push(suggestedElement);

          break;
        }
      }
    }
  }

  function _getEmptyGridFields(grid: IGrid): number {
    let sum = 0;

    for (const element of grid.elements) {
      if (element?.securityElement) {
        sum += element.securityElement?.fields_number;
      }
    }

    return grid.width - sum;
  }

  // endregion

  function setConfigurationLoaded(loaded: boolean): void {
    if (_configuration) _configuration!.dataLoaded = loaded;
  }

  function resetSelectedGridIndex(): void {
    _configuration.selectedGridIndex = 0;
  }

  function setSelectedGridIndex(index: number, securityGrid = false): void {
    if (!_configuration.case) {
      return;
    }

    if (
      (_configuration.case.grids &&
        _configuration.case.grids.length > index &&
        index >= 0 &&
        !securityGrid) ||
      (_configuration.case.securityGrids &&
        _configuration.case.securityGrids.length > index &&
        index >= 0 &&
        securityGrid)
    ) {
      _configuration.selectedGridIndex = index;
      setSelectedBoardSet(null);
    }
  }

  function addEmptyElement(gridId?: number): void {
    if (!_configuration.case || !_configuration.case.grids) {
      return;
    }

    if (gridId == undefined) {
      for (const grid of _configuration.case.grids) {
        const _emptyElement: IBoardSet = {
          uId: Functions.uId(),
        };

        grid.elements.push(_emptyElement);
      }

      return;
    }

    if (_configuration.case.grids[gridId]) {
      const _emptyElement: IBoardSet = {
        uId: Functions.uId(),
      };

      _configuration.case.grids[gridId].elements.push(_emptyElement);
    }
  }

  function selectProducerOfBoardSet(
    boardSetUid: string,
    producer?: IProducer,
    showErrorIfNoSpace: boolean = true
  ): IBoardActionResponse {
    if (!selectedGrid.value)
      return { error: true, message: trans("invalidItemSelected") };

    const selectedBoardSet = selectedGrid.value?.elements.find(
      (boardSet) => boardSet.uId == boardSetUid
    );

    if (!selectedBoardSet || !selectedBoardSet.boardElement) {
      return { error: true, message: trans("anUnexpectedErrorOccurred") };
    } else {
      setSelectedBoardSet(selectedBoardSet);
    }

    let elementPosition: IBoardPosition | undefined;
    let elementProducer: IProducer | undefined;

    if (producer && !producer.board_element_details) {
      selectedBoardSet.producer = null;
      selectedBoardSet.position = null;

      console.error(
        "Wybrany element tablicowy prawdopodbnie nie ma dodanych szczegółów dla tej marki"
      );

      return { error: true, message: trans("anUnexpectedErrorOccurred") };
    }

    if (
      producer &&
      selectedBoardSet.boardElement.producers.find(
        (prod) => prod.id == producer.id
      )
    ) {
      const tmpBoardSet: IBoardSet = {
        uId: "",
        producer: producer,
      };

      const newElementMap = getArrayElementMap(tmpBoardSet);

      const firstEmptySpace = getFirstEmptySpaceOfBoardOfSize(
        newElementMap,
        producer.board_element_details.length,
        producer.board_element_details.depth,
        boardSetUid
      );

      if (
        firstEmptySpace &&
        Object.prototype.hasOwnProperty.call(firstEmptySpace, "error") &&
        (firstEmptySpace as IBoardActionResponse).error
      ) {
        selectedBoardSet.producer = null;
        selectedBoardSet.position = null;

        return firstEmptySpace as IBoardActionResponse;
      }

      if (firstEmptySpace != null) {
        elementProducer = producer;
        elementPosition = firstEmptySpace as IBoardPosition;
      } else if (showErrorIfNoSpace) {
        return {
          error: true,
          message: trans("selectedElementDoesNotFitInGrid"),
        };
      }
    }

    selectedBoardSet.producer = elementProducer;
    selectedBoardSet.position = elementPosition;

    addEmptyIfAllCompleted();

    forceRepaint();
    return { error: false };
  }

  function addEmptyIfAllCompleted(
    gridIndex: number = _configuration.selectedGridIndex
  ): void {
    if (!_configuration.case || !_configuration.case.grids) return;
    let completed = true;

    for (const element of _configuration.case.grids[gridIndex].elements) {
      if (!element.boardElement || !element.producer) {
        completed = false;

        break;
      }
    }

    if (completed) {
      addEmptyElement(gridIndex);
    }
  }

  function selectBoardElementOfBoardSet(
    boardSetUid: string,
    boardElement: IBoardElement
  ): IBoardActionResponse {
    if (!selectedGrid.value)
      return { error: true, message: trans("invalidItemSelected") };

    const selectedBoardSet = selectedGrid.value.elements.find(
      (boardSet) => boardSet.uId == boardSetUid
    );

    if (!selectedBoardSet) {
      return { error: true, message: trans("anUnexpectedErrorOccurred") };
    }

    let currentProducer: IProducer | undefined;

    if (boardElement.producers.length) {
      currentProducer = boardElement.producers[0];
    } else if (
      selectedBoardSet.producer &&
      boardElement.producers.find(
        (prod) => prod.id === selectedBoardSet.producer!.id
      )
    ) {
      currentProducer = boardElement.producers.find(
        (prod) => prod.id === selectedBoardSet.producer!.id
      );
    }

    selectedBoardSet.boardElement = boardElement;

    const selectedProducerError = selectProducerOfBoardSet(
      boardSetUid,
      currentProducer,
      true
    );

    if (selectedBoardSet) {
      setSelectedBoardSet(selectedBoardSet);
    }

    if (selectedProducerError.error) {
      return selectedProducerError;
    }

    forceRepaint();
    return { error: false };
  }

  function getFirstEmptySpaceOfBoardOfSize(
    newElementMap: number[][],
    width: number,
    height: number,
    skipBoardSetId?: string
  ): IBoardPosition | null | IBoardActionResponse {
    if (!selectedGrid.value)
      return {
        error: true,
        message: trans("anUnexpectedErrorOccurred"),
      };

    const boardWidth: number = selectedGrid.value.width;
    const boardHeight: number = selectedGrid.value.height;
    let checkFirstPosition: IBoardPosition | undefined;

    if (width > boardWidth || height > boardHeight)
      return {
        error: true,
        message: trans("selectedElementDoesNotFitInGrid"),
      };

    const gridMap: number[][] = [...Array(boardHeight)].map(() =>
      Array(boardWidth).fill(0)
    );

    if (selectedGrid.value) {
      for (const element of selectedGrid.value.elements) {
        if (
          element.boardElement &&
          element.position &&
          element.producer &&
          element.uId != skipBoardSetId
        ) {
          const paddingTop = element.producer.model_3d?.paddingTop
            ? element.producer.model_3d.paddingTop
            : 0;
          const paddingBottom = element.producer.model_3d?.paddingBottom
            ? element.producer.model_3d.paddingBottom
            : 0;
          const paddingLeft = element.producer.model_3d?.paddingLeft
            ? element.producer.model_3d.paddingLeft
            : 0;
          const paddingRight = element.producer.model_3d?.paddingRight
            ? element.producer.model_3d.paddingRight
            : 0;

          const elementMap = getArrayElementMap(element);

          const insertResponse = insertArray(
            gridMap,
            elementMap,
            element.position.left,
            element.position.top,
            paddingTop,
            paddingBottom,
            paddingLeft,
            paddingRight
          );

          if (insertResponse.error) return insertResponse;
        } else if (
          element.boardElement &&
          element.position &&
          element.uId == skipBoardSetId
        ) {
          checkFirstPosition = element.position;
        }
      }
    }

    const newElementMapElementStartPosition: IBoardPosition = {
      top: 0,
      left: 0,
    };

    for (let i = 0; i < newElementMap.length; i++) {
      let doBreak = false;

      for (let j = 0; j < newElementMap.length; j++) {
        if (newElementMap[i][j] == 2) {
          newElementMapElementStartPosition.top = i;
          newElementMapElementStartPosition.left = j;
          doBreak = true;
          break;
        }
      }

      if (doBreak) break;
    }

    if (checkFirstPosition) {
      if (
        checkIfAreaOfMatrixIsEmpty(
          gridMap,
          newElementMap,
          newElementMapElementStartPosition,
          checkFirstPosition,
          width,
          height
        )
      )
        return checkFirstPosition;
    }

    return findEmptyRectangleOfSizeInGrid(
      gridMap,
      newElementMap,
      newElementMapElementStartPosition,
      width,
      height
    );
  }

  function getArrayElementMap(element: IBoardSet): number[][] {
    if (element.producer) {
      const paddingTop = element.producer.model_3d?.paddingTop
        ? element.producer.model_3d.paddingTop
        : 0;
      const paddingBottom = element.producer.model_3d?.paddingBottom
        ? element.producer.model_3d.paddingBottom
        : 0;
      const paddingLeft = element.producer.model_3d?.paddingLeft
        ? element.producer.model_3d.paddingLeft
        : 0;
      const paddingRight = element.producer.model_3d?.paddingRight
        ? element.producer.model_3d.paddingRight
        : 0;

      const elementMap: number[][] = [
        ...Array(
          element.producer?.board_element_details.depth +
            paddingTop +
            paddingBottom
        ),
      ].map(() =>
        Array(
          element.producer?.board_element_details.length! +
            paddingLeft +
            paddingRight
        ).fill(1)
      );

      for (let i = 0; i < elementMap.length; i++) {
        for (let j = 0; j < elementMap[0].length; j++) {
          const isInTopBottomRange =
            i >= paddingTop && i < elementMap.length - paddingBottom;
          const isInLeftRightRange =
            j >= paddingLeft && j < elementMap[0].length - paddingRight;

          if (isInTopBottomRange && isInLeftRightRange) {
            elementMap[i][j] = 2;
          }
        }
      }

      return elementMap;
    }

    return [];
  }

  function findEmptyRectangleOfSizeInGrid(
    matrix: number[][],
    newElementMap: number[][],
    newElementMapElementStartPosition: IBoardPosition,
    width: number,
    height: number
  ): IBoardPosition | null {
    const boardHeight: number = matrix.length;
    const boardWidth: number = boardHeight > 0 ? matrix[0].length : 0;

    if (
      width < 1 ||
      height < 1 ||
      boardHeight < 1 ||
      boardWidth < 1 ||
      width > boardWidth ||
      height > boardHeight
    ) {
      return null;
    }

    for (let i = 0; i < boardWidth - width + 1; i++) {
      for (let j = 0; j < boardHeight - height + 1; j++) {
        const startPosition = { left: i, top: j } as IBoardPosition;

        if (
          checkIfAreaOfMatrixIsEmpty(
            matrix,
            newElementMap,
            newElementMapElementStartPosition,
            startPosition,
            width,
            height
          )
        )
          return startPosition;
      }
    }

    return null;
  }

  function checkIfAreaOfMatrixIsEmpty(
    matrix: number[][],
    newElementMap: number[][],
    newElementMapElementStartPosition: IBoardPosition,
    startPosition: IBoardPosition,
    width: number,
    height: number
  ): boolean {
    const boardHeight: number = matrix.length;
    const boardWidth: number = boardHeight > 0 ? matrix[0].length : 0;

    if (
      width < 1 ||
      height < 1 ||
      boardHeight < 1 ||
      boardWidth < 1 ||
      width > boardWidth ||
      height > boardHeight ||
      startPosition.top < 0 ||
      startPosition.left < 0 ||
      startPosition.top + height > boardHeight ||
      startPosition.left + width > boardWidth
    ) {
      return false;
    }

    for (let i = 0; i < newElementMap.length; i++) {
      const bigArrayY =
        i - newElementMapElementStartPosition.top + startPosition.top;

      if (bigArrayY >= 0 && bigArrayY < matrix.length) {
        for (let j = 0; j < newElementMap[0].length; j++) {
          const bigArrayX =
            j - newElementMapElementStartPosition.left + startPosition.left;

          if (bigArrayX >= 0 && bigArrayX < matrix[0].length) {
            if (
              matrix[bigArrayY][bigArrayX] == 2 ||
              (matrix[bigArrayY][bigArrayX] == 1 && newElementMap[i][j] == 2)
            ) {
              return false;
            }
          }
        }
      }
    }

    return true;
  }

  function insertArray(
    big: number[][],
    small: number[][],
    x: number,
    y: number,
    paddingTop: number,
    paddingBottom: number,
    paddingLeft: number,
    paddingRight: number
  ): IBoardActionResponse {
    if (
      small.length - paddingTop - paddingBottom + y > big.length ||
      small[0].length - paddingLeft - paddingRight + x > big[0].length
    )
      return {
        error: true,
        message: trans("selectedElementDoesNotFitInGrid"),
      };

    for (let i = 0; i < small.length; i++) {
      const bigArrayY = i - paddingTop + y;

      if (bigArrayY >= 0 && bigArrayY < big.length) {
        for (let j = 0; j < small[0].length; j++) {
          const bigArrayX = j - paddingLeft + x;

          if (bigArrayX >= 0 && bigArrayX < big[0].length) {
            big[bigArrayY][bigArrayX] = small[i][j];
          }
        }
      }
    }

    return { error: false };
  }

  function deleteBoardElementsFromSelected(boardSetUid: string): void {
    if (
      !selectedGrid.value ||
      !selectedGrid.value.elements ||
      !selectedGrid.value.elements.length
    ) {
      return;
    }

    const indexToDelete = selectedGrid.value.elements.findIndex(
      (item) => item.uId === boardSetUid
    );

    if (indexToDelete > -1) {
      if (
        selectedBoardSet.value?.uId ===
        selectedGrid.value.elements[indexToDelete].uId
      ) {
        setSelectedBoardSet(null);
      }

      selectedGrid.value.elements.splice(indexToDelete, 1);
      addEmptyIfAllCompleted();
      forceRepaint();
    }
  }

  function setConfigurationCategory(category: ISwitchboardCategory): void {
    _configuration.category = category;
  }

  function setConfigurationCase(caseItem: ICase): void {
    _configuration.case = caseItem as ICase;

    if (!_configuration.case!.grids) {
      _configuration.case!.grids = [];
    }

    if (!_configuration.case!.securityGrids) {
      _configuration.case!.securityGrids = [];
    }

    storeCaseGltfModel(null);
  }

  function setCaseGrids(grids: Array<IGrid>): void {
    resetSelectedGridIndex();

    if (_configuration.case && _configuration.case.grids) {
      clearCaseGrids();

      for (const grid of grids) {
        if (!grid.elements) {
          grid.elements = [];
        }

        _configuration.case!.grids.push(grid);
      }

      for (let i = 0; i < _configuration.case!.grids.length; i++) {
        addEmptyIfAllCompleted(i);
      }
    }
  }

  function clearCaseGrids(): void {
    if (_configuration.case && _configuration.case.grids) {
      while (_configuration.case!.grids.length) {
        _configuration.case!.grids.pop();
      }
    }
  }

  // region Accessories
  function setEmptyAccessoriesArray() {
    _configuration.accessories = [] as IAccessories[];
  }

  function addToSelectedAccessories(selectedItem: IAccessories): void {
    selectedItem._isSelected = true;

    if (_configuration.accessories) {
      _configuration.accessories.push(selectedItem);
    }
  }

  function deleteAccessoriesFromSelected(selectedItem: IAccessories): void {
    if (!_configuration.accessories || !_configuration.accessories.length) {
      return;
    }

    const indexToDelete = _configuration.accessories.findIndex(
      (item) => JSON.stringify(item) === JSON.stringify(selectedItem)
    );

    if (indexToDelete > -1) {
      _configuration.accessories.splice(indexToDelete, 1);
      selectedItem._isSelected = false;
    }
  }

  function setAccessories(accessoriesItems: Array<IAccessories>): void {
    const tempAccessories: IAccessories[] = [];

    accessoriesItems.forEach((item: IAccessories) => {
      tempAccessories.push(item);
      _configuration.accessories = tempAccessories;
    });
  }

  function clearConfiguration(): void {
    for (const prop of Object.getOwnPropertyNames(_configuration)) {
      delete _configuration[prop as keyof typeof _configuration];
    }
  }

  // endregion
  // endregion

  return {
    getConfiguration,
    configurationList,
    getConfigurationCategoryId,
    getCaseId,
    setConfigurationCategory,
    setConfigurationCase,
    setCaseGrids,
    setCaseSecurityGrids,
    clearConfiguration,
    setAccessories,
    setSelectedGridIndex,
    addEmptyElement,
    selectBoardElementOfBoardSet,
    selectProducerOfBoardSet,
    deleteBoardElementsFromSelected,
    configuration,
    selectedCase,
    grids,
    securityGrids,
    selectedGrid,
    dataLoaded,
    setConfigurationLoaded,
    clearCaseGrids,
    clearCaseSecurityGrids,
    setSelectedSecurityOtherProducer,
    setSelectedPhotovoltaicOtherProducer,
    selectSecurityElementOfBoardSet,
    deleteSecurityElementsFromSelected,
    forceRepaintWatcher,
    forceRepaint3DWatcher,
    updateGridElementPosition,
    setSelectedBoardSet,
    selectedBoardSet,
    getGridElementById,
    selectedSecurityGrid,
    addSuggestedSecurityElementsToGrid,
    gltfCaseModel,
    storeCaseGltfModel,
    clearSecurityGridElements,
    configurationPrice,
    configurationGrossPrice,
    configurationIp,
    configurationCurrencyCode,
    setEmptyAccessoriesArray,
    addToSelectedAccessories,
    deleteAccessoriesFromSelected,
    forceRepaint,
    isPhotovoltaic,
    isCamping,
    addSuggestedPhotovoltaicBoardElements,
    getProducersWithElementsSecurities,
    getPreparedConfigurationToSend,
    quantity,
    setQuantity,
    setVisualisation3dRef,
  };
});
