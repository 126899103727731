import type ISelectOption from "@/types/ISelectOption";

export default class Functions {
  static isNumber(n: any): boolean {
    return !isNaN(parseFloat(n)) && isFinite(n);
  }

  static uId(): string {
    const a = new Uint32Array(3);
    window.crypto.getRandomValues(a);

    return (
      performance.now().toString(36) +
      Array.from(a)
        .map((A) => A.toString(36))
        .join("")
    ).replace(/\./g, "");
  }

  static randomId(): number {
    return window.crypto.getRandomValues(new Uint32Array(1))[0];
  }

  static parseToFloat(value: number): number {
    return parseFloat(value.toLocaleString().replace(/,/, "."));
  }

  static formatDate(date: Date | string): string {
    const d = new Date(date);

    return d.toLocaleDateString("pl-PL", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    });
  }

  static formatDateTime(date: Date | string): string {
    const d = new Date(date);

    return d.toLocaleString();
  }

  static formatTime(date: Date | string): string {
    const d = new Date(date);

    let hours: string | number = d.getHours();
    let minutes: string | number = d.getMinutes();

    if (hours < 10) {
      hours = `0${hours}`;
    }

    if (minutes < 10) {
      minutes = `0${minutes}`;
    }

    return hours + ":" + minutes;
  }

  static formatFileSize(size: any): string {
    const units = ["B", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    let l = 0,
      n = parseInt(size, 10) || 0;

    while (n >= 1024 && ++l) {
      n = n / 1024;
    }

    return n.toFixed(2) + " " + units[l];
  }

  static buildUrlParams(paramsData: any): string {
    let paramsString = "";

    if (paramsData) {
      const tempParamObject: any = {};

      for (const param in paramsData) {
        if (
          Object.prototype.hasOwnProperty.call(paramsData, param) &&
          (paramsData[param] ||
            paramsData[param] === 0 ||
            paramsData[param] === "")
        ) {
          tempParamObject[param] = paramsData[param];
        }
      }

      paramsString = new URLSearchParams(tempParamObject).toString();
    }

    return paramsString.length ? "?" + paramsString : "";
  }

  static getFormData(model: any, form?: FormData, namespace = ""): FormData {
    const formData = form || new FormData();
    let formKey;

    for (const propertyName in model) {
      if (
        // eslint-disable-next-line no-prototype-builtins
        (!model.hasOwnProperty(propertyName) || !model[propertyName]) &&
        !(typeof model[propertyName] == "boolean")
      )
        continue;
      const formKey = namespace
        ? `${namespace}[${propertyName}]`
        : propertyName;
      if (model[propertyName] instanceof Date)
        formData.append(formKey, model[propertyName].toISOString());
      else if (model[propertyName] instanceof Array) {
        model[propertyName].forEach((element: any, index: number) => {
          const tempFormKey = `${formKey}[${index}]`;
          this.getFormData(element, formData, tempFormKey);
        });
      } else if (
        typeof model[propertyName] === "object" &&
        !(model[propertyName] instanceof File)
      )
        this.getFormData(model[propertyName], formData, formKey);
      else formData.append(formKey, model[propertyName].toString());
    }
    return formData;
  }

  static textToCsvFile(content: any, fileName: string): Blob {
    const blob: any = new Blob([content], { type: "text/csv;charset=utf-8;" });

    blob.lastModifiedDate = new Date();
    blob.name = fileName ? fileName : "file.csv";

    return blob;
  }

  static scrollToTop(): void {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }

  static dateToTimestamp(strDate: string): number {
    const date = Date.parse(strDate);

    return date / 1000;
  }

  static formatCurrency(currency: string): string {
    switch (currency) {
      case "PLN":
        return "zł";
      case "EUR":
        return "€";
      default:
        return "zł";
    }
  }

  static formatPrice(price: number, currencyCode: string): string {
    const preparedPrice = price.toFixed(2).replace(".", ",");

    return `${preparedPrice} ${Functions.formatCurrency(currencyCode)}`;
  }

  static sortAlphabeticallySelectOptions(
    options: ISelectOption[]
  ): ISelectOption[] {
    return options.sort((a, b) => a.label.localeCompare(b.label));
  }
}
